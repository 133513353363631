import React from 'react';
import {Helmet} from 'react-helmet';

const Head = () => {
    return (
        <div>
            <Helmet>
                <meta name="theme-color" content="#121212"></meta>
                <title>Mieden.me | Online portfolio</title>
                <link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap" rel="stylesheet" />
            </Helmet>
        </div>
    );
}

export default Head;
